import React, { ReactElement } from "react";
import supabase from "@library/api/supabase";
import { OnboardingPayload } from "@library/domain/onboarding";
import { User } from "@library/domain/user";
import { OnNextReturn } from "../../slides";
import api from "@library/api";
import { Link } from "react-router-dom";

export const onNext = async ({
  index,
  payload,
  setPayload,
  user,
  setUser,
  setIsAuthenticated,
}: {
  index: number;
  payload: Partial<OnboardingPayload>;
  setPayload: (value: Partial<OnboardingPayload>) => void;
  user: Partial<User>;
  setUser: (data: User) => void;
  setIsAuthenticated: (value: boolean) => void;
}): Promise<OnNextReturn> => {
  if (!payload.user) payload.user = {};
  if (payload?.user?.email) {
    payload.user.email = payload.user.email.toLowerCase();
  }
  let supabaseId = payload.user.supabaseId;
  if (!supabaseId) {
    try {
      const sbid = JSON.parse(
        localStorage.getItem(
          `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`,
        ) || "{}",
      )?.user?.id;
      if (sbid && sbid.length > 5) {
        supabaseId = sbid;
        payload.user.supabaseId = supabaseId;
      }
    } catch (e) {
      // Do nothing
    }
  }
  if (!supabaseId) {
    // Set a random password that the user will never user
    // All users have to either reset password or use magic links
    const password = "Fa!<3_" + Math.random().toString(36).slice(-16);
    const email = payload?.user?.email;
    if (!email) {
      return {
        error: "Please provide an email.",
      };
    }
    const { data: supabaseData, error: supabaseError } =
      await supabase.auth.signUp({
        email,
        password,
      });
    if (supabaseError) {
      let error = String(supabaseError) as string | ReactElement;
      if (String(supabaseError).includes("already registered")) {
        error = (
          <span>
            User is already registered. <Link to="/login">Log in</Link> to
            continue where you left off.
          </span>
        );
      }
      return {
        error,
      };
    }
    supabaseId = supabaseData.user?.id;
    payload.user.supabaseId = supabaseId;
  }
  const { data, error: onboardingError } = await api.post("onboarding", {
    payload,
    activeStep: index,
  });
  if (onboardingError) {
    setPayload(payload);
    return {
      error: String(onboardingError),
    };
  }
  const _user = {
    ...user,
    ...payload.user,
    ...data.user,
  };
  setPayload(payload);
  setUser(_user);
  setIsAuthenticated(true);
  return {};
};
